<template>
  <main
    class="w-full rounded-xl shadow-xl sm:w-8/12 xl:w-6/12 mx-auto bg-white"
  >
    <div class="w-full px-6 py-8 md:p-8">
      <h4 class="text-xl md:text-3xl font-medium">Security</h4>
      <hr class="text-dark-400 my-4" />

      <div class="flex flex-col gap-4 pt-4 pb-2">
        <!-- /////// Change Pin ////// -->
        <div
          @click="showChangePin = !showChangePin"
          class="cursor-pointer p-4 md:p-6 border-2 shadow-sm border-white bg-secondary-crepe rounded-2xl flex items-center justify-between"
        >
          <div class="flex gap-4 items-center">
            <span class="w-12 sm:w-20">
              <img src="@/assets/icons/newPadlock.svg" class="w-full" />
            </span>
            <span class="text-xl sm:text-2xl font-medium">Change Pin</span>
          </div>
          <i
            v-if="showChangePin"
            class="fas fa-angle-down text-2xl text-dark-600"
          ></i>
          <i v-else class="fas fa-angle-right text-2xl text-dark-600"></i>
        </div>

        <change-pin v-if="showChangePin" @close="showChangePin = false" />

        <!-- ////////// Two Factor Authentication ////////// -->
        <div
          :class="
            companyAdmin && companyAdmin.companyAdmin.twoFactor
              ? 'bg-successStatus'
              : 'bg-success'
          "
          class="cursor-pointer p-4 md:p-5 border-2 shadow-sm border-white rounded-2xl flex gap-4 items-center justify-between"
        >
          <div @click="showTwoFactor = true" class="flex gap-4 items-center">
            <span class="w-14 sm:w-24 md:w-20">
              <img
                v-if="companyAdmin && companyAdmin.companyAdmin.twoFactor"
                src="@/assets/icons/2FAPadlock-active.svg"
                class="w-full"
              />
              <img
                v-else
                src="@/assets/icons/newPadlock2.svg"
                class="w-full"
              />
            </span>
            <span class="text-xl sm:text-2xl font-medium text-white"
              >Set Up Two Factor Authentication</span
            >
          </div>
          <img
            @click="disableTwoFA"
            v-if="companyAdmin && companyAdmin.companyAdmin.twoFactor"
            src="@/assets/icons/active2FA.svg"
          />
          <img
            v-else
            @click="showTwoFactor = true"
            src="@/assets/icons/newToggle.svg"
          />
        </div>

        <p
          v-if="
            companyAdmin &&
            companyAdmin.twoFactorAuth &&
            companyAdmin.twoFactorAuth.authType === 'SMS'
          "
          class="text-dark-600 text-xs md:text-sm"
        >
          This is how you will verify your identity when you log in. We will
          send OTP verification to your registered phone number.
        </p>
        <p
          v-if="
            companyAdmin &&
            companyAdmin.twoFactorAuth &&
            companyAdmin.twoFactorAuth.authType === 'SecurityQuestions'
          "
          class="text-dark-600 text-xs md:text-sm"
        >
          Make sure the answer to your security question is what only you know.
          <span>
            <easiButton
              variant="text"
              @click="showSecurityQuestion = true"
              color="info"
              size="small"
              class="font-bold -ml-2 -mt-2"
              >Change Security Question</easiButton
            >
          </span>
        </p>
        <p
          v-if="
            companyAdmin &&
            companyAdmin.twoFactorAuth &&
            companyAdmin.twoFactorAuth.authType === 'Authenticator'
          "
          class="text-dark-600 text-xs md:text-sm"
        >
          If you lose access to your authentication device, use any of your
          backup codes to login to your account.
          <span>
            <easiButton
              :loading="backupLoading"
              @click="createBackupCode"
              variant="text"
              color="info"
              size="small"
              class="font-bold -ml-2 -mt-2"
              >Generate New Backup Codes</easiButton
            >
          </span>
        </p>

        <two-factor v-if="showTwoFactor" @close="showTwoFactor = false" />
      </div>
    </div>
    <easiModal
      :isHeader="false"
      v-if="backupModal"
      @close="backupModal = false"
    >
      <BackupCode />
    </easiModal>

    <question-verify
      v-if="showSecurityQuestion"
      @close="showSecurityQuestion = false"
    />
    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

import ChangePin from "@/components/Settings/Security/ChangePin.vue";
import TwoFactor from "@/components/Settings/Security/TwoFactor.vue";
import BackupCode from "@/components/Settings/Security/BackupCode";
import QuestionVerify from "@/components/Settings/Security/QuestionVerify.vue";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const { log } = helperFunctions;

// window.localStorage.removeItem("backupCode");

const companyAdmin = computed(() => store.getCompanyAdmin);

const loading = ref(false);
const showSecurityQuestion = ref(false);
const showChangePin = ref(false);
const showTwoFactor = ref(false);
const backupModal = ref(false);
const backupLoading = ref(false);

onMounted(() => {
  queryAllSecurityQuestions();
  queryUserSecurityQuestions();
});

const disableTwoFA = async () => {
  showTwoFactor.value = false;
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "DisableTwoFa",
      data: {},
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      loading.value = false;
      toast.success(res.message);
      queryAdmin();
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
  }
};

const createBackupCode = async () => {
  backupLoading.value = true;
  try {
    let res = await mutate({
      endpoint: "CreateBackUpCodes",
      data: {},
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      backupLoading.value = false;
      window.localStorage.setItem("backupCode", JSON.stringify(res.data));
      backupModal.value = true;
      queryAdmin();
    } else {
      backupLoading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    backupLoading.value = false;
    console.log(e.message);
  }
};

const queryAdmin = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    console.log(e.message);
  }
};
const queryAllSecurityQuestions = async () => {
  try {
    await query({
      endpoint: "ListSecurityQuestionsTwoFa",
      service: "SETTINGS",
      storeKey: "allSecurityQuestions",
    });
  } catch (e) {
    console.log(e.message);
  }
};
const queryUserSecurityQuestions = async () => {
  try {
    await query({
      endpoint: "ListSecurityQuestions",
      service: "SETTINGS",
      storeKey: "userSecurityQuestionsFA",
    });
  } catch (e) {
    console.log(e.message);
  }
};
</script>

<style></style>
