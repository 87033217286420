<template>
  <easiModal
    rounded="2xl"
    :isBorder="false"
    v-if="showModal"
    @close="closeModal"
  >
    <template v-slot:header>
      <h1 class="capitalize font-medium text-center text-headerText text-2xl">
        Set Up Two Factor Authentication
      </h1>
    </template>

    <div class="px-6 ms:px-8 py-0">
      <div
        class="p-x-10 rounded-lg text-center leading-6 text-sm md:text-base font-bold md:font-normal text-dark-900 w-10/12 mx-auto"
      >
        For every transaction on the app, you’ll be required to complete a 2nd
        verification step
      </div>

      <div class="flex flex-col w-full gap-4 py-4">
        <div
          @click="selected = i"
          v-for="(item, i) in options"
          :key="item.type"
          :class="
            selected === i
              ? 'shadow-md border border-primary bg-highlight'
              : 'border border-outlineGray'
          "
          class="relative cursor-pointer flex items-center justify-between gap-4 md:gap-6 rounded-xl p-4 w-full"
        >
          <div class="flex items-center gap-4">
            <span>
              <img v-show="selected === i" :src="item.iconActive" alt="" />
              <img v-show="selected !== i" :src="item.iconNull" alt="" />
            </span>
            <span class="text-lg font-medium">{{ item.title }}</span>
          </div>

          <span class="">
            <img
              v-show="selected === i"
              src="@/assets/icons/check-active-green.svg"
              alt=""
            />
            <img
              v-show="selected !== i"
              src="@/assets/icons/check-null.svg"
              alt=""
            />
          </span>
        </div>
        <easiButton :loading="loading" @click="submit" block class="">
          Submit
        </easiButton>
      </div>
    </div>
  </easiModal>

  <sms-verify v-if="smsModal" @close="smsModal = false" />
  <question-verify v-if="questionModal" @close="questionModal = false" />
  <google-verify v-if="googleModal" @close="googleModal = false" />

  <easiSuccess v-if="addSuccess" @close="addSuccess = false">
    <template v-slot:message>
      <span>Account added successfully</span>
    </template>
  </easiSuccess>

  <easiRoundLoader v-if="loading" />
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { ref, reactive, watch, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";

import SmsVerify from "@/components/Settings/Security/SmsVerify.vue";
import QuestionVerify from "@/components/Settings/Security/QuestionVerify.vue";
import GoogleVerify from "@/components/Settings/Security/GoogleVerify.vue";

import smsNull from "@/assets/icons/sms-null.svg";
import smsActive from "@/assets/icons/newSms.svg";

import questionNull from "@/assets/icons/question-null.svg";
import questionActive from "@/assets/icons/newQuestion.svg";

import googleNull from "@/assets/icons/google-auth-null.svg";
import googleActive from "@/assets/icons/newAuthenticator.svg";

const store = useDataStore();
const { processNumber, log } = helperFunctions;
const { mutate, set } = useDataStore();
const toast = useToast();
const router = useRouter();

const companyAdmin = computed(() => store.getCompanyAdmin);

const selected = ref(null);

const showModal = ref(true);
const smsModal = ref(false);
const questionModal = ref(false);
const googleModal = ref(false);
const addSuccess = ref(false);
const loading = ref(false);

const args = reactive({});

const options = reactive([
  {
    title: "Verify via SMS",
    iconNull: smsNull,
    iconActive: smsActive,

    type: "SMS",
    // action: async () => {
    //   if (
    //     companyAdmin.value &&
    //     companyAdmin.value.twoFactorAuth &&
    //     companyAdmin.value.twoFactorAuth.authType === "SMS"
    //   ) {
    //     return false;
    //   } else {
    //     await sendOtp();
    //     smsModal.value = true;
    //     selected.value = "1";
    //   }
    // },
  },
  {
    title: "Set up security question",
    iconNull: questionNull,
    iconActive: questionActive,

    type: "SecurityQuestions",
    // action: async () => {
    //   if (
    //     companyAdmin.value &&
    //     companyAdmin.value.twoFactorAuth &&
    //     companyAdmin.value.twoFactorAuth.authType === "SecurityQuestions"
    //   ) {
    //     return false;
    //   } else {
    //     questionModal.value = true;
    //     selected.value = "2";
    //   }
    // },
  },
  {
    title: "Verify via Google Authenticator",
    iconNull: googleNull,
    iconActive: googleActive,

    type: "Authenticator",
    // action: async () => {
    //   if (
    //     companyAdmin.value &&
    //     companyAdmin.value.twoFactorAuth &&
    //     companyAdmin.value.twoFactorAuth.authType === "Authenticator"
    //   ) {
    //     return false;
    //   } else {
    //     await createAuthenticatorSecret();
    //     googleModal.value = true;
    //     selected.value = "3";
    //   }
    // },
  },
]);

const emit = defineEmits(["update", "close"]);
function close() {
  emit("close");
}

const submit = async () => {
  if (selected.value === 0) {
    await sendOtp();
    smsModal.value = true;
  } else if (selected.value === 1) {
    questionModal.value = true;
  } else if (selected.value === 2) {
    await createAuthenticatorSecret();
    googleModal.value = true;
  } else {
    toast.error("Please select an option");
  }
};

const sendOtp = async () => {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: {
        input: {
          value:
            companyAdmin.value && companyAdmin.value.companyAdmin.phoneNumber,
          type: "OTP_ENABLE",
          channel: "SMS",
        },
      },
      service: "AUTH",
    });
    log(res);
    if (res.success) {
      loading.value = false;
      window.localStorage.setItem("otpReferrence", res.data.reference);
      // toast.success(res.message);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e.message);
  }
};

const createAuthenticatorSecret = async () => {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "CreateAuthenticatorSecret",
      data: {},
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      loading.value = false;
      window.localStorage.setItem("authSecrete", JSON.stringify(res.data));
      // toast.success(res.message);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e.message);
  }
};

const closeModal = () => {
  showModal.value = false;
  close();
};
</script>

<style></style>
