<template>
  <div class="w-full flex justify-center items-center px-6 pb-2 text-2xl">
    <h1 class="capitalize font-medium text-headerText">
      2 Factor Authentication Enabled
    </h1>
  </div>

  <hr class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor" />
  <div class="px-8">
    <div class="w-44 h-44 mx-auto my-6">
      <img src="@/assets/img/backup.png" alt="w-full object-contain" />
    </div>
  </div>

  <hr class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor" />

  <div class="px-8">
    <span
      class="text-xs md:text-sm md:text-md mt-1 block text-center font-medium"
    >
      Download and store these backup codes somewhere safe. If you lose access
      to your authentication device, you can use any of these codes to login to
      your account
    </span>
  </div>
  <hr class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor" />
  <div class="px-8 my-4">
    <div
      class="mx-auto mb-6 w-8/12 flex justify-center gap-2 items-center text-primary font-bold"
    >
      <div class="grid grid-rows-5 grid-cols-2 gap-x-4">
        <span v-for="code in backupCode" :key="code">
          {{ code }}
        </span>
        <span></span>
      </div>
    </div>
    <easiButton
      @click="downloadBackUp"
      :loading="loading"
      class="w-full md:w-fit"
    >
      <span class="text-sm"> Download Backup Code </span>
    </easiButton>
  </div>
</template>

<script setup>
import { onMounted, ref } from "@vue/runtime-core";

const loading = ref(false);
const backupCode = ref(JSON.parse(window.localStorage.getItem("backupCode")));

onMounted(() => {
  backupCode.value = JSON.parse(window.localStorage.getItem("backupCode"));
});

const downloadBackUp = async (e) => {
  loading.value = true;
  let code = "";
  backupCode.value.forEach((el) => {
    code = `${code} ${el}`;
  });

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(code)
  );
  element.setAttribute("download", "Backup_code");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  await document.body.removeChild(element);

  loading.value = false;

  //   await navigator.clipboard.writeText(code);
  //   toast.success("Back up code copied to clipboard");
  //   setTimeout(() => {
  //     closeModal();
  //   }, 1000);
};
</script>

<style></style>
