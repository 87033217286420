<template>
  <form @submit.prevent="submit" ref="form" autocomplete="on" class="pt-2 pb-4">
    <div>
      <span class="capitalize text-dark-800 font-normal text-base"
        >Current Pin
      </span>
      <span
        class="mb-2 w-fit flex gap-x-3 md:gap-x-0 justify-startitems-center"
      >
        <easiOtp
          justify="between"
          class="w-full sm:w-auto"
          :type="showPin ? 'password' : 'number'"
          size="10"
          :token_length="6"
          :max-length="1"
          @input="checkoldPin"
          :error="errorRules.oldPin"
        />
        <img
          v-if="showPin"
          @click="showPin = !showPin"
          class="cursor-pointer"
          src="@/assets/icons/eye-close.svg"
        />
        <img
          v-else
          @click="showPin = !showPin"
          class="cursor-pointer"
          src="@/assets/icons/eye-open.svg"
        />
      </span>
    </div>
    <div>
      <span class="capitalize text-dark-800 font-normal text-base"
        >New Pin
      </span>
      <span
        class="mb-2 w-fit flex gap-x-3 md:gap-x-0 justify-start items-center"
      >
        <easiOtp
          justify="between"
          class="w-full sm:w-auto"
          :type="showPin ? 'password' : 'number'"
          size="10"
          :token_length="6"
          :max-length="1"
          @input="checkPin"
          :error="errorRules.pin"
        />
        <img
          v-if="showPin"
          @click="showPin = !showPin"
          class="cursor-pointer"
          src="@/assets/icons/eye-close.svg"
        />
        <img
          v-else
          @click="showPin = !showPin"
          class="cursor-pointer"
          src="@/assets/icons/eye-open.svg"
        />
      </span>
    </div>
    <div>
      <span class="capitalize text-dark-800 font-normal text-base"
        >Confirm pin</span
      >

      <span class="mb-2 flex gap-x-3 md:gap-x-0 justify-center items-center">
        <easiOtp
          justify="between"
          class=""
          :type="showPin ? 'password' : 'number'"
          size="10"
          :token_length="6"
          :max-length="1"
          @input="checkConfirmPin"
          :error="errorRules.confirmPin"
        />
        <img
          v-if="showPin"
          @click="showPin = !showPin"
          class="cursor-pointer"
          src="@/assets/icons/eye-close.svg"
        />
        <img
          v-else
          @click="showPin = !showPin"
          class="cursor-pointer"
          src="@/assets/icons/eye-open.svg"
        />
      </span>
    </div>

    <easiButton :loading="loading" class="mt-4" block>Submit</easiButton>
  </form>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import { logout } from "@/api/api";

const store = useDataStore();
const toast = useToast();
const { query, mutate } = store;
const { log } = helperFunctions;

const loading = ref(false);
const showPin = ref(false);

const args = reactive({
  oldPin: "",
  pin: "",
  confirmPin: "",
});

//// error rules for input fields ////
const errorRules = reactive({
  oldPin: false,
  pin: false,
  confirmPin: false,
});
async function submit() {
  // clear errors before submitting
  errorRules.oldPin = false;
  errorRules.pin = false;
  errorRules.confirmPin = false;

  if (!args.oldPin) {
    errorRules.oldPin = "Old pin is required";
    return;
  }
  if (!args.pin) {
    errorRules.pin = "New pin is required";

    return;
  }
  if (args.pin !== args.confirmPin) {
    errorRules.confirmPin = "New pin must be the same";

    return;
  }

  const payload = {
    oldPin: args.oldPin,
    pin: args.pin,
    confirmPin: args.confirmPin,
  };
  try {
    loading.value = true;
    const res = await mutate({
      endpoint: "ResetPassword",
      data: {
        input: payload,
      },
      service: "AUTH",
    });

    if (res.success) {
      logout("logout");
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
}
function validateForm() {
  if (args.oldPin.trim() === "") {
    errorRules.oldPin = "Current pin doe not correct";
    return false;
  } else if (args.pin.trim() === "") {
    errorRules.pin = "Please set a pin";
    return false;
  } else if (args.confirmPin.trim() === "") {
    errorRules.confirmPin = "Please confirm your pin";
    return false;
  } else if (args.pin !== args.confirmPin) {
    errorRules.pin = "Pin does not match. Please check and put a matching pin";
    return false;
  } else {
    //Reset Error
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });

    return true;
  }
}

//Update OTP Input Functions
function checkoldPin(otp_input) {
  args.oldPin = otp_input;
}

function checkPin(otp_input) {
  args.pin = otp_input;
}

function checkConfirmPin(otp_input) {
  args.confirmPin = otp_input;
}

const confirmPin = (otp) => ({});

const emit = defineEmits(["update", "close"]);
function close() {
  emit("close");
}
</script>

<style></style>
