<template>
  <easiModal @close="close">
    <template v-slot:header>
      <h1 class="capitalize font-medium text-2xl md:text-3xl text-headerText">
        Set Up Security Question
      </h1>
    </template>

    <div class="px-8">
      <!-- <span class="text-sm md:text-md mt-1 font-medium block text-center">
        Please select security question
      </span> -->

      <label for="question" class="text-dark-800 text-sm font-medium"
        >Select Question</label
      >
      <easiSelectInput2
        required
        class="mt-1 mb-3"
        @update="args.question = $event"
        :value="args.question"
        :options="formattedQuestion"
        :error="errorRules.question"
        placeholder="Select Question"
      />
      <!-- autoHeight -->

      <h3 class="text-sm md:text-2xl my-6 bg-mint rounded-xl py-1 px-2">
        {{ args.question }}
      </h3>

      <div>
        <!-- <label for="answer" class="text-dark-800 text-sm font-medium"
          >Your Answer</label
        > -->
        <easiTextInput
          placeholder="Enter your answer"
          v-model="args.answer"
          type="text"
          name="answer"
          class="mb-4 mt-1"
          :error="errorRules.answer"
        />
      </div>
    </div>
    <hr class="mt-4 mb-8 border-0.5 border-dividerColor text-dividerColor" />
    <div
      class="px-8 flex-col-reverse flex md:grid grid-cols-2 justify-center items-center gap-4 my-4"
    >
      <div class="w-full col-span-2 md:col-span-1">
        <easiButton @click="close" variant="outlined" class="bg-white w-full">
          <span class="text-sm text-dark-800"> Cancel </span>
        </easiButton>
      </div>
      <div class="w-full col-span-2 md:col-span-1">
        <easiButton
          :loading="loading"
          @click="enableTwoFaQuestion"
          class="w-full"
        >
          <span class="text-sm"> Enable </span>
        </easiButton>
      </div>
    </div>
  </easiModal>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const { log } = helperFunctions;

const companyAdmin = computed(() => store.getCompanyAdmin);
const securityQuestions = computed(() => store.getAllSecurityQuestions);
const formattedQuestion = securityQuestions.value.map((el) => {
  return {
    label: el.question,
    value: el.question,
  };
});

console.log(securityQuestions.value);
console.log(formattedQuestion.value);

const loading = ref(false);
const args = reactive({
  question: "",
  answer: "",
});

//// error rules for input fields ////
const errorRules = reactive({
  question: false,
  answer: false,
});

function validateForm() {
  if (args.question.trim() === "") {
    errorRules.question = "Please, select a question";
    return false;
  } else if (args.answer.trim() === "") {
    errorRules.answer = "Please, input an answer";
    return false;
  } else {
    //Reset Error
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });

    return true;
  }
}

const getQuestionId = () => {
  const question = securityQuestions.value.find(
    (el) => el.question === args.question
  );
  return question._id;
};

const enableTwoFaQuestion = async () => {
  if (validateForm()) {
    loading.value = true;

    if (companyAdmin.value && companyAdmin.value.companyAdmin.twoFactor) {
      await disableTwoFA();
    }

    try {
      let res = await mutate({
        endpoint: "EnableTwoFa",
        data: {
          input: {
            authType: "SecurityQuestions",
            securityQuestions: {
              questions: [
                {
                  answer: args.answer,
                  question: getQuestionId(args.question),
                },
              ],
            },
          },
        },
        service: "SETTINGS",
      });
      log(res);
      if (res.success) {
        loading.value = false;
        toast.success(res.message);
        queryAdmin();
        close();
      } else {
        loading.value = false;
        toast.error(res.message);
      }
    } catch (e) {
      loading.value = false;
    }
  }
};

const queryAdmin = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    loading.value = false;
  }
};

const disableTwoFA = async () => {
  try {
    let res = await mutate({
      endpoint: "DisableTwoFa",
      data: {},
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      // toast.success(res.message);
      // window.location.reload();
      queryAdmin();
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
  }
};

const emit = defineEmits(["update", "close"]);
function close() {
  emit("close");
}
</script>

<style></style>
