<template>
  <easiModal @close="close">
    <template v-slot:header>
      <h1 class="capitalize font-medium text-headerText md:text-3xl">
        Using Authenticator App
      </h1>
    </template>

    <div class="px-8">
      <p class="leading-4 text-xs md:text-sm font-medium my-3">
        1. To enable 2FA, you need to install an authenticator app like Google
        Authenticator.
      </p>
      <p class="leading-4 text-xs md:text-sm font-medium my-3">
        2. Scan the QR Code below with your authenticator app. If you can’t scan
        this barcode, enter the text code
        <span
          @click="copyFN(authSecrete && authSecrete.secret)"
          class="text-dark-800 font-bold"
          >{{ authSecrete && authSecrete.secret }}</span
        >
        on the authenticator app instead
      </p>

      <div
        class="w-44 h-44 border border-dark-200 overflow-hidden mx-auto my-6"
      >
        <img
          :src="authSecrete && authSecrete.qrCode"
          class="w-full object-contain"
          alt="QR Code"
        />
      </div>
    </div>


    <div class="px-8">
      <span class="text-sm md:text-md mt-1 font-medium block text-center">
        Enter OTP generated by app and click Enable
      </span>

      <span class="my-4 flex justify-around items-center">
        <easiOtp
          v-model="otpCode"
          size="10"
          :token_length="6"
          :max-length="1"
          @input="checkOTP"
          :error="errorRules.otpCode"
        />
      </span>
    </div>
    <div
      class="px-8 flex-col-reverse flex md:grid grid-cols-2 justify-center items-center gap-4 my-4"
    >
      <div class="w-full col-span-2 md:col-span-1">
        <easiButton @click="close" variant="outlined" class="bg-white w-full">
          <span class="text-sm text-dark-800"> Cancel </span>
        </easiButton>
      </div>
      <div class="w-full col-span-2 md:col-span-1">
        <easiButton
          :loading="loading"
          @click="enableTwoFaGoogle"
          class="w-full"
        >
          <span class="text-sm"> Enable </span>
        </easiButton>
      </div>
    </div>
  </easiModal>

  <!-- ////////// Download Backup Code Modal ////////// -->
  <easiModal :isHeader="false" v-if="backupModal" @close="closeModal">
    <BackupCode />
  </easiModal>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

import BackupCode from "@/components/Settings/Security/BackupCode";

const store = useDataStore();
const toast = useToast();
const { query, mutate } = store;
const { log } = helperFunctions;

const authSecrete = JSON.parse(window.localStorage.getItem("authSecrete"));
const companyAdmin = computed(() => store.getCompanyAdmin);

log(authSecrete);

const loading = ref(false);
const backupModal = ref(false);

const otpCode = ref("");
const errorRules = reactive({
  otpCode: false,
});

function checkOTP(otp_input) {
  otpCode.value = otp_input;
}

const enableTwoFaGoogle = async () => {
  if (otpCode.value.trim() !== "") {
    errorRules.otpCode = false;
    loading.value = true;

    if (companyAdmin.value && companyAdmin.value.companyAdmin.twoFactor) {
      await disableTwoFA();
    }

    try {
      let res = await mutate({
        endpoint: "EnableTwoFa",
        data: {
          input: {
            authType: "Authenticator",
            authenticator: {
              code: otpCode.value,
            },
          },
        },
        service: "SETTINGS",
      });

      if (res.success) {
        toast.success(res.message);
        const isBackup = window.localStorage.getItem("backupCode");
        if (!isBackup) {
          createBackupCode();
        } else {
          loading.value = false;
          queryAdmin();
          closeModal();
        }
      } else {
        loading.value = false;
        toast.error(res.message);
      }
    } catch (e) {
      loading.value = false;
    }
  } else {
    errorRules.otpCode = "Please enter the code";
  }
};

const disableTwoFA = async () => {
  try {
    let res = await mutate({
      endpoint: "DisableTwoFa",
      data: {},
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      // toast.success(res.message);
      queryAdmin();
    } else {
      loading.value.enable = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value.enable = false;
  }
};

const createBackupCode = async () => {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "CreateBackUpCodes",
      data: {},
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      loading.value = false;
      window.localStorage.setItem("backupCode", JSON.stringify(res.data));
      backupModal.value = true;
      queryAdmin();
    } else {
      loading.value = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    console.log(e.message);
  }
};

const copyFN = async (arg) => {
  await navigator.clipboard.writeText(arg);
  toast.success("Content copied to clipboard");
};

const queryAdmin = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    loading.value = false;
  }
};

const emit = defineEmits(["update", "close"]);
function close() {
  window.localStorage.removeItem("authSecrete");
  emit("close");
}

function closeModal() {
  backupModal.modelValue = false;
  close();
}
</script>

<style></style>
