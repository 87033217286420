<template>
  <easiModal @close="close">
    <template v-slot:header>
      <h1 class="capitalize font-medium text-headerText">Verify Via SMS</h1>
    </template>

    <div class="px-8 text-center">
      <span class="text-sm md:text-md mt-1 font-medium block">
        Please input OTP sent to your registered phone number
      </span>

      <span class="my-4 flex justify-around items-center">
        <easiOtp
          v-model="otpCode"
          size="10"
          :token_length="6"
          :max-length="1"
          @input="checkOTP"
          :error="errorRules.otpCode"
        />
      </span>
      <div
        class="text-dark-800 mb-4 text-base font-medium text-center flex items-center justify-center"
      >
        Did not receive OTP?
        <easiButton
          @click="resendOtp"
          :loading="loading.otp"
          variant="text"
          color="secondary"
          size="small"
          class="px-0 font bold text-base ml-2"
          >Resend</easiButton
        >
      </div>
    </div>
    <hr class="mt-2 mb-8 border-0.5 border-dividerColor text-dividerColor" />
    <div
      class="px-8 flex-col-reverse flex md:grid grid-cols-2 justify-center items-center gap-4 my-4"
    >
      <div class="w-full col-span-2 md:col-span-1">
        <easiButton @click="close" variant="outlined" class="bg-white w-full">
          <span class="text-sm text-dark-800"> Cancel </span>
        </easiButton>
      </div>
      <div class="w-full col-span-2 md:col-span-1">
        <easiButton
          @click="confirmOtp"
          :loading="loading.enable"
          class="w-full"
        >
          <span class="text-sm"> Enable </span>
        </easiButton>
      </div>
    </div>
  </easiModal>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

const store = useDataStore();
const toast = useToast();
const { query, mutate } = store;
const { log } = helperFunctions;

const companyAdmin = computed(() => store.getCompanyAdmin);

const loading = ref({
  otp: false,
  enable: false,
});

const otpCode = ref("");
const otpReference = ref("");
const errorRules = reactive({
  otpCode: false,
});

function checkOTP(otp_input) {
  otpCode.value = otp_input;
}

const resendOtp = async () => {
  loading.value.otp = true;

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: {
        input: {
          value:
            companyAdmin.value && companyAdmin.value.companyAdmin.phoneNumber,
          type: "OTP_ENABLE",
          channel: "SMS",
        },
      },
      service: "AUTH",
    });
    log(res);
    if (res.success) {
      loading.value.otp = false;
      window.localStorage.setItem("otpReferrence", res.data.reference);
      toast.success(res.message);
    } else {
      loading.value.otp = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value.otp = false;
    console.log(e.message);
  }
};

const confirmOtp = async () => {
  if (otpCode.value.trim() !== "") {
    errorRules.otpCode = false;
    loading.value.enable = true;

    try {
      let res = await mutate({
        endpoint: "ConfirmOTP",
        data: {
          input: {
            code: otpCode.value,
            value:
              companyAdmin.value && companyAdmin.value.companyAdmin.phoneNumber,
            reference: window.localStorage.getItem("otpReferrence"),
          },
        },
        service: "AUTH",
      });

      if (res.success) {
        //Check if TwoFA is enabled and disable it
        if (companyAdmin.value && companyAdmin.value.companyAdmin.twoFactor) {
          await disableTwoFA();
        }

        await enableTwoFaSMS();
      } else {
        loading.value.enable = false;
        errorRules.otpCode = res.message;
        // toast.error(res.message);
      }
    } catch (e) {
      loading.value.enable = false;
    }
  } else {
    errorRules.otpCode = "Please enter the otp pin";
  }
};

const enableTwoFaSMS = async () => {
  try {
    let res = await mutate({
      endpoint: "EnableTwoFa",
      data: {
        input: {
          authType: "SMS",
          sms: {
            otpRef: window.localStorage.getItem("otpReferrence"),
          },
        },
      },
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      loading.value.enable = false;
      toast.success(res.message);
      queryAdmin();
      close();
    } else {
      loading.value.enable = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value.enable = false;
  }
};

const disableTwoFA = async () => {
  try {
    let res = await mutate({
      endpoint: "DisableTwoFa",
      data: {},
      service: "SETTINGS",
    });
    log(res);
    if (res.success) {
      // toast.success(res.message);
      // window.location.reload();
      queryAdmin();
    } else {
      loading.value.enable = false;
      toast.error(res.message);
    }
  } catch (e) {
    loading.value.enable = false;
  }
};

const queryAdmin = async () => {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    loading.value = false;
  }
};

const emit = defineEmits(["update", "close"]);
function close() {
  emit("close");
}
</script>

<style></style>
